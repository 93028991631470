<template>

	<div v-if="contentList[step] != null">


		<!-- 교육 영상자료 -->
		<ans-mov v-if="contentList[step].contentType === 'mov'" :eduMain="eduMain" :edu-content="contentList[step]" :edu-answer="answerList[step]" @goPrev="goPrev" @goNext="goNext" @goComplete="goComplete"></ans-mov>


		<!-- 테스트 문항 -->
		<ans-tst v-if="contentList[step].contentType === 'tst' " :edu-main="eduMain" :edu-content="contentList[step]" :edu-answer="answerList[step]" @goPrev="goPrev" @goNext="goNext" @goComplete="goComplete"></ans-tst>

	</div>

</template>

<script>

import AnsMov from "@/components/education/answer/ansMov";
import AnsTst from "@/components/education/answer/ansTst";

export default {
	name: "eduProceed",
	components: {
		AnsTst, AnsMov
	},
	data () {

		let playStep = "";
		if ( !this.stringUtils.isEmpty(this.$route.query.playStep) ) {
			playStep = this.stringUtils.valInt(this.$route.query.playStep)
		}

		return {

			emIdx: this.$route.params.emIdx,
			userIdDec: atob(this.$route.params.userIdEnc),

			// 교육컨텐츠 정보
			eduMain: {},
			studentVO: {},
			contentList: [],
			answerList: [],

			step: 0,
			playStep,
		}
	},
	created() {

		if ( this.isLogin() && this.getLoginId() === this.userIdDec ) {
			this.getEduMain( this.emIdx, this.getLoginId() );
			this.getAnswers( this.emIdx, this.getLoginId() );
		} else {

			// 로그인 강제 이동
			this.goLogin()
			/*this.swalUtils.fire( "로그인 후 사용가능합니다.", "로그인 화면으로 이동합니다.", "warning" )
			.then((result) => {
				let temp = location.pathname + "?" + Qs.stringify( this.$route.query )
				location.href = "/member/login?referrer=" + encodeURIComponent(temp);
			})*/
		}

		window.eduView = this
	},
	mounted() {
	},
	methods: {

		getEduMain (_emIdx, _userId) {

			var self = this

			self.$eventBus.$emit("startProgress", 1)
			this.axiosCaller.get(this, this.APIs.EDU_MAIN + "/getEduMain", {
				emIdx : _emIdx,
				userId : _userId,
			}, function (res) {
				const result = res.data
				console.log(result)
				if ( result.status === "ok" ) {
					self.eduMain = result.eduMain
				}
				//console.log("allContentCnt ::::: " + self.allContentCnt)
				self.$eventBus.$emit("doneProgress", 1)
			})

		},


		/**
		 * 이수자의 현재까지 이수정보 조회
		 * @param _emIdx
		 * @param _userId
		 */
		getAnswers (_emIdx, _userId) {

			var self = this;
			self.$eventBus.$emit("startProgress", 2)
			this.axiosCaller.get(this, this.APIs.EDU_ANSWER + "/", {
				emIdx: _emIdx,
				userId: _userId,
				doOpen: true,
			}, function (res) {
				self.$eventBus.$emit("doneProgress", 2)

				console.log("getAnswers")
				const result = res.data;
				console.log(result)

				if ( result.status === "ok" ) {

					console.log( result )
					self.eduMain = result.eduMainVO;
					self.studentVO = self.eduMain.studentVO;
					self.contentList = self.eduMain.contentList;
					self.answerList = result.answerList;

					if ( !self.eduMain.isDisplay ) {
						this.swalUtils.gritter("Error", "공개되지 않은 교육이거나 수정중인 교육입니다.", "danger")
						//alert( "공개되지 않은 교육이거나 수정중인 교육입니다." );
						window.close()
					}

					self.setCurrentAnswer();
				}

			})

		},



		/**
		 * 조회된 이수내용 조회
		 * answerList.length == 0 : 첫단계부터 교육 시작
		 * answerList.length > 0 : 현재 답변 내용 조회 후 이어서 시작.
		 */
		setCurrentAnswer () {

			//console.log("setCurrentAnswer")

			var self = this;
			this.answerList.forEach(value => {

				if ( value.isComplete ) {
					self.step++;
				}

			})

			if ( this.stringUtils.isEmpty(this.playStep) ) {    // 일반 수강화면

				if ( this.step >= this.answerList.length ) {    // 이미 이수된 교육일 경우
					// if ( this.step >= this.answerList.length && this.studentVO.isComplete ) {

					this.swalUtils.fire( "이수가 완료된 교육입니다.", "교육상세 페이지에서 완료처리를 눌러주세요." )
						.then(() => {

							if ( opener != null ) {
								self.appClose({
									reload: true
								})
							} else {
								history.back()
							}

						})

				}
			} else {    // 다시보기일 경우
				this.step = this.playStep
			}

			//console.log("self.step ::::: " + self.step)
		},



		/**
		 * 다음단계 버튼
		 * 컨텐츠 수 내 : 다음단계
		 * 컨텐츠 마지막 : 완료 처리 후 창 닫기
		 */
		goNext () {

			if ( this.contentList.length > this.step + 1 ) {
				//this.step++;
				// window.location.reload(true)
				location.href = location.pathname

			} else {

				// TODO : eduStudent 이수 완료 처리
				var self = this
				self.$eventBus.$emit("startProgress")
				this.axiosCaller.put(this, this.APIs.EDU_STUDENT + "/complete", {
					emIdx: this.eduMain.emIdx,
					userId: this.getLoginId(),
				}, function (res) {
					self.$eventBus.$emit("doneProgress")

					const result = res.data;

					if ( result.status === "ok" ) {

						self.swalUtils.gritter( "교육완료", "교육 이수가 완료되었습니다.", "info" )
							.then(() => {

								if ( opener != null ) {
									self.appClose({
										reload: true
									})
								} else {
									history.back()
								}
							})
					}

				})
			}

		},




		goComplete: function (isForce = false) {
			console.log("goComplete")
			this.$router.push({
				path: '/answerComplete/' + this.answerList[this.step].eaIdx + '/' + btoa(this.userIdDec),
				query: {
					emIdx: this.eduMain.emIdx,
					ecIdx: this.contentList[this.step].ecIdx,
				}
			});
		},




		isNext () {

			if ( this.contentList.length > this.step + 1 ) {
				return true
			} else {
				return false
			}
		},



		goPrev () {

			if ( 0 <= this.step - 1 ) {
				this.step--;
			} else {

			}

		},

		doClose () {
			this.appClose({
				reload: true,
			})
		},
	},
}
</script>

<style scoped>

</style>