<template>

	<div id="div-main" class="wrap" :class="qstLayout">
		<!-- header -->
		<header class="header-step">
			<div class="head-left">
				<button type="button" class="btn-back" @click="stopProceed"><span class="blind">이전 페이지</span></button>
			</div>
			<div class="head-center">
				<div class="head-tit">
					<span class="page-tit">Book Test</span>
				</div>
			</div>
			<div class="head-right"></div>
		</header>


		<!-- 스코어 -->
		<template v-if="isFinal && !isComplete">
			<tst-score :ec-idx="eduContent.ecIdx" :user-id="eduAnswer.userId"></tst-score>
		</template>


		<!-- 완료 + 리포트 -->
		<template v-else-if="isFinal && isComplete">
			<tst-report :ec-idx="eduContent.ecIdx" :user-id="eduAnswer.userId" :edu-main="eduMain"></tst-report>
		</template>


		<!-- 테스트 진행 -->
		<div class="container" v-else>


			<div class="content">
				<div class="test-head">
					<div class="test-pager">
						<span :class="{ 'active': (idx <= index) }" v-for="(item, idx) in qstTstList">{{ idx + 1 }}</span>
					</div>
					<div class="guide-text">{{ qstTstList[index].tstTitle }}</div>
					<button type="button" class="check-btn" @click="saveAnswer" style="z-index: 999"><i></i>Check</button>
				</div>


				<!-- 드래그앤 드랍 문제일 경우 -->
				<template v-if="qstTstList[index].isDrag">
					<tst-drag :qst-tst="qstTstList[index]" :answer-list="qstTstList[index].answerList" :is-shuffle="qstTstList[index].isShuffle" ref="dnd"></tst-drag>
				</template>

				<!-- 일반 객관식 문제일 경우 -->
				<div class="test-cont" v-else>
					<div class="test-question">
						<!-- 이미지의 width는 실제 이미지 width의 1/2-->
						<template v-if="qstTstList[index].isAudio">
							<button type="button" class="btn-sound"><img src="/assets/images/test/ico_sound.png" alt="" width="48" @click="playQstAuio"></button>
							<audio id="qstAudio" :src="qstTstList[index].tstAudioSrc"></audio>
						</template>

						<template v-if="qstTstList[index].isImg">
							<div class="img-text"><img :src="qstTstList[index].tstImgSrc" alt=""></div>
						</template>
					</div>


					<!-- 객관식 개수에 따른 split-* 클래스 필요 -->
					<ul class="test-answer" :class="'split-' + qstTstList[index].answerList.length">

						<!-- 단일선택 -->
						<template v-if="qstTstList[index].tstType === 'single'">

							<template v-if="!qstTstList[index].isOX">
								<li v-for="(ans, idx) in qstTstList[index].answerList">
									<input type="radio" :id="'single-' + ans.taIdx" :name="'single-' + ans.qtIdx" :value="ans.taIdx" v-model="ansTstList[index].taIdx" @click="logAnswer">
									<label :for="'single-' + ans.taIdx">
										<img :src="ans.answerImgSrc" alt="" v-if="!stringUtils.isEmpty(ans.answerImgSrc)">
										<span v-if="!stringUtils.isEmpty(ans.answer)">{{ ans.answer }}</span>
									</label>
								</li>
							</template>
							<template v-if="qstTstList[index].isOX">
								<li :class="'answer-' + ans.answer.toLowerCase()" v-for="(ans, idx) in qstTstList[index].answerList">
									<input type="radio" :id="'single-' + ans.taIdx" :name="'single-' + ans.qtIdx" :value="ans.taIdx" v-model="ansTstList[index].taIdx" @click="logAnswer">
									<label :for="'single-' + ans.taIdx">
										<i></i>
									</label>
								</li>
							</template>

						</template>

						<!-- 다중선택 -->
						<template v-if="qstTstList[index].tstType === 'multi'">
							<li v-for="(ans, idx) in qstTstList[index].answerList">
								<input type="checkbox" :id="'multi-' + ans.taIdx" :name="'multi-' + ans.taIdx" :value="ans.taIdx" v-model="ansTstList[index].taIdxs" @click="logAnswer">
								<label :for="'multi-' + ans.taIdx">
									<img :src="ans.answerImgSrc" alt="" v-if="!stringUtils.isEmpty(ans.answerImgSrc)">
									<span v-if="!stringUtils.isEmpty(ans.answer)">{{ ans.answer }}</span>
								</label>
							</li>
						</template>

						<!-- 주관식 -->
						<template v-if="qstTstList[index].tstType === 'short'">
							<input type="text" id="ans-short" v-model="ansTstList[index].shortTxt" placeholder="주관식 답안을 입력해주세요."/>
						</template>


						<!-- 답안항목 오디오 -->
						<audio :id="'ans-sound-' + ans.taIdx" :src="ans.answerAudioSrc" v-if="!stringUtils.isEmpty(ans.answerAudioSrc)" v-for="(ans, idx) in qstTstList[index].answerList"></audio>


					</ul>

				</div>


				<div class="test-answer-result">
					<img id="ok-layout" src="/assets/images/test/img_o.png" alt="" style="display: none;">
					<img id="fail-layout" src="/assets/images/test/img_x.png" alt="" style="display: none;">

                    <audio id="ok-sound" :src="'/sound/correct/' + numberUtils.randNum(1, 5) + '.mp3'"></audio>
					<audio id="fail-sound" :src="'/sound/incorrect/' + numberUtils.randNum(1, 2) + '.mp3'"></audio>
				</div>

			</div>
		</div>
	</div>

</template>

<script>
import TstScore from "@/components/education/answer/tstScore";
import TstReport from "@/components/education/answer/tstReport";
import TstDrag from "@/components/education/answer/tstDrag";

export default {
	name: "ansTst",
	components: {TstDrag, TstReport, TstScore},
	props: [
		"eduMain",
		"eduContent",
		"eduAnswer",
	],
	data() {

		return {

			userId: this.getLoginId(),
			qstTstList: this.eduContent.qstTstList,
			qstLayout: ["p-test-ty2"],

			index: 0,
			number: 1,
			setAnsTst: {},
			ansTstList: this.eduAnswer.ansTstList,

			isStart: false,
			isFinal: false,
			isComplete: false,
			correctCount: 0,        // 정답수
			showIndex: 0,

			isSolve: false,
		}
	},
	created() {

		// 선택답안 순서 섞기
		this.qstTstList.forEach(qstTst => {
			if (qstTst.isShuffle && !qstTst.isDrag) {
				this.arrayUtils.shuffle(qstTst.answerList)
			}
		})

		/**
		 * 초기 + 이어하기 답변 목록 설정
		 */
		if (this.ansTstList != null && this.ansTstList.length > 0) {
			this.prevAnswers();
		}
	},
	mounted() {

	},
	methods: {


		/**
		 * 기존 답안 현재 답안 형태로 설정
		 */
		prevAnswers: function () {

			var self = this;
			//console.log("prevAnswers")
			//console.log(this.ansTstList)

			var i = 0;
			var stop = 0;
			this.ansTstList.forEach(vo => {

				vo.taIdx = 0
				vo.taIdxs = []

				if (!this.stringUtils.isEmpty(vo.selTaIdxs) || !this.stringUtils.isEmpty(vo.shortTxt)) {

					if (self.qstTstList[i].tstType === "single") {
						vo.taIdx = parseInt(vo.selTaIdxs);
					} else if (self.qstTstList[i].tstType === "multi") {
						vo.taIdxs = vo.selTaIdxs.split(",");
					} else if (self.qstTstList[i].tstType === "short") {
						//vo.shortTxt = vo.shortTxt;
					}
					i++;

					self.isStart = true;

					// if: 문제를 다 풀었을 경우 isFinal 설정
					// else: 안푼 문제까지 index 증가
					if (self.index + 1 >= self.qstTstList.length) {
						self.isFinal = true;

						if (self.eduAnswer.isComplete) {
							self.isComplete = true
						}

					} else {

						console.log(self.ansTstList[self.index].selTaIdxs)

						// 중간에 답이 없을 경우 스템 증가 중단
						// ex : 관리자에서 수동으로 완료처리 했을 경우
						// 객관식과 주관식 둘중 하나라도 답이 있으면 다음 문제
						if (!self.stringUtils.isEmpty(self.ansTstList[self.index].selTaIdxs) || !self.stringUtils.isEmpty(self.ansTstList[self.index].shortTxt)) {
							self.index++;
						}
					}
				} else {
					//self.index--;

					// 중간에 끊긴 답안이 있을 경우 해당 번호 저장
					/*if ( stop <= 0 ) {
						stop = i;
					}*/
				}
			})

			self.$nextTick(() => {
				self.setQstLayout();
			})


			// 답안 끊긴 번호 있을 경우 : 해당번호로 이동
			// 없을 경우 : 인덱스로 이동
			/*if ( stop > 0 ) {
				self.index = stop;
			}*/

		},


		/**
		 * 현재 답변 저장
		 */
		saveAnswer: function () {

			//console.log("saveAnswer")
			if (this.qstTstList[this.index].tstType === "single" || this.qstTstList[this.index].tstType === "multi") {

				if (this.qstTstList[this.index].isDrag) {
					if (!this.$refs.dnd.chkSubmitAnswer()) {
						this.swalUtils.gritter("선택된 답이 없습니다.", "답안을 선택해주세요.", "warning")
						return false;
					}
				} else {

					// 답안 체크 여부 확인
					if (this.ansTstList[this.index].taIdx === 0 && this.ansTstList[this.index].taIdxs.length <= 0) {
						this.swalUtils.gritter("선택된 답이 없습니다.", "답안을 선택해주세요.", "warning")
						//alert( "답안을 선택해주세요." );
						return false;
					}

				}

			} else if (this.qstTstList[this.index].tstType === "short") {

				if (this.ansTstList[this.index].shortTxt.trim() === "") {
					this.swalUtils.gritter("선택된 답이 없습니다.", "주관식 답안을 입력해주세요.", "warning")
					//alert( "주관식 답안을 입력해주세요." );
					return false;
				}

			}

			var self = this;
			// 현재 선택답 설정
			if (self.qstTstList[self.index].isDrag) {
				console.log(self.ansTstList[self.index].selTaIdxs)
				self.ansTstList[self.index].selTaIdxs = self.$refs.dnd.getAnswer()
			} else {
				if (self.qstTstList[self.index].tstType === "single") {
					self.ansTstList[self.index].selTaIdxs = self.ansTstList[self.index].taIdx;
				} else if (self.qstTstList[self.index].tstType === "multi") {
					self.ansTstList[self.index].selTaIdxs = self.ansTstList[self.index].taIdxs.join(",");
				}
			}

			/**
			 * if 문제가 오답체크 설정되어 있고 처음 풀때만 저장
			 * else 정답체크만
			 */
			if (!self.isSolve || !self.qstTstList[self.index].directCorrect) {

				this.axiosCaller.patch(this, this.APIs.EDU_ANSWER + "/tstAns", self.ansTstList[self.index], async function (res) {

					const result = res.data;
					if (result.status === "ok") {

						// 첫풀이 했음으로 설정
						self.isSolve = true
						if (self.qstTstList[self.index].isDrag) {
							self.showCorrect(self.$refs.dnd.chkAnswerAll())
						} else {
							self.showCorrect(result.ansTstVO.isCorrect);
						}

						// 마지막 문제 풀이 완료에 테스트 기록 저장
						const recordResult = await self.doRecording()
						console.log( recordResult )
					}
				})


			} else {


				this.axiosCaller.get(this, this.APIs.EDU_ANSWER + "/tstAns/isCorrect", self.ansTstList[self.index], function (res) {
					const result = res.data;
					if (result.status === "ok") {
						if (self.qstTstList[self.index].isDrag) {
							self.showCorrect(self.$refs.dnd.chkAnswerAll())
						} else {
							self.showCorrect(result.isCorrect);
						}
					}
				})


			}

		},




		/**
		 * 테스트 기록 저장
		 */
		doRecording() {
			if (this.qstTstList.length <= this.index + 1) {

				const self = this
				return new Promise((resolve, reject) => {

					self.axiosCaller.put(self, self.APIs.EDU_ANSWER + "/record", {
						ecIdx: this.eduContent.ecIdx,
						userId: this.getLoginId(),
						recordMode: "record",
						contentType: "tst",
					}, (res) => {
						const result = res.data
						if ( result.status === "ok" ) {
							resolve(result)
						} else {
							reject(result)
						}
					})
				})
			}
		},


		showCorrect(_isCorrect) {

			const self = this
			// 기존 답변 결과 동작중지
			document.querySelector('#ok-layout').style.display = 'none';
			document.querySelector('#fail-layout').style.display = 'none';
			document.querySelector('#ok-sound').pause();
			document.querySelector('#fail-sound').pause();

			if (_isCorrect) {
				document.querySelector('#ok-layout').style.display = 'block';
				document.querySelector('#ok-sound').play();
			} else {
				document.querySelector('#fail-layout').style.display = 'block';
				document.querySelector('#fail-sound').play();
			}

			setTimeout(() => {
				document.querySelector('#ok-layout').style.display = 'none';
				document.querySelector('#fail-layout').style.display = 'none';
				// document.querySelector('#ok-sound').pause();
				// document.querySelector('#fail-sound').pause();

				if (_isCorrect || !self.qstTstList[self.index].directCorrect) {
					this.$eventBus.$emit("startProgress")
					location.reload()
				}

			}, 3000)
		},


		/**
		 * 답안 제출 + 일괄 저장
		 */
		doSubmit: function () {

			this.$eventBus.$emit("startProgress")

			var saveAnswers = [];
			// TODO : 답안 실제 저장처리
			var i = 0;
			this.ansTstList.forEach(answer => {

				// 문항 변경시 체크를 위한 선택문항 데이터 재설정
				answer.qtIdx = this.qstTstList[i].qtIdx

				// 현재 선택답 설정
				if (this.qstTstList[i].tstType === "single") {
					answer.selTaIdxs = answer.taIdx;
				} else if (this.qstTstList[i].tstType === "multi") {
					answer.selTaIdxs = answer.taIdxs.join(",");
				} else if (this.qstTstList[i].tstType === "dnd") {
					answer.selTaIdxs = answer.taIdx;
				}

				saveAnswers.push(answer)
				i++;
			})


			var self = this
			this.axiosCaller.put(this, this.APIs.EDU_ANSWER + "/tstAns", {
				'ecIdx': this.eduContent.ecIdx,
				'userId': this.getLoginId(),
				'answers': this.ansTstList,
			}, function (res) {
				self.$eventBus.$emit("doneProgress")

				const result = res.data;
				console.log(result)
				self.isComplete = true;
				self.correctCount = result.correctCount;

				// 다음 교육으로 이동
				/*if ( result.status == "ok" ) {
					self.$emit("goNext")
				}*/

			})


		},


		/**
		 * 현재까지의 답변 확인용
		 */
		logAnswer: function (e) {
			//console.log(this.index)
			// console.log(this.ansTstList[this.index].taIdx)
			// console.log(this.ansTstList[this.index].taIdxs)
			// console.log(this.ansTstList[this.index].shortTxt)
			const sound = document.querySelector('#ans-sound-' + e.target.value);
			if (sound != null) {
				sound.play();
			}
		},


		setQstLayout() {

			const self = this

			// 스코어 or 리포트일 경우
			if (this.isFinal && !this.isComplete) {
				document.querySelector('#div-main').classList.remove("p-test")
				this.qstLayout = ["p-test-result"];
			} else if (this.isFinal && this.isComplete) {
				this.qstLayout = ["p-test-report"];
			} else {

				// p-test-ty1: 이미지 답안
				// p-test-ty2: OX
				// p-test-ty3: 텍스트
				const qstTstVO = this.qstTstList[this.index]
				const answerList = this.qstTstList[this.index].answerList

				// DONE : OX 문제인지 확인
				if (qstTstVO.isOX) {
					this.qstLayout = ["p-test", "p-test-ty2"];
					return
				}


				// DONE : 텍스트만 있는 문제인지 확인
				if (!qstTstVO.isOX) {
					let isText = true
					answerList.forEach(value => {
						if (!self.stringUtils.isEmpty(value.answerImgSrc)) {
							isText = false;
						}
					})
					if (isText) {
						this.qstLayout = ["p-test", "p-test-ty3"];
						return
					}
				}

				// DONE : 이미지 문제인지 확인
				if (qstTstVO.isImg) {
					return this.qstLayout = ["p-test", "p-test-ty1"];
				}

				return this.qstLayout = ["p-test", "p-test-ty1"];

			}

		},


		editAnsTst: function () {

			this.ansTstList[this.index].taIdx = this.setAnsTst.taIdx;
			this.ansTstList[this.index].taIdxs = this.setAnsTst.taIdxs;
			this.ansTstList[this.index].shortTxt = this.setAnsTst.shortTxt;

			const tmpList = this.ansTstList;
			this.ansTstList = []
			this.ansTstList = tmpList
		},


		playQstAuio() {
			document.querySelector('#qstAudio').play();
			//$('#qstAudio').play()
		},


		// doSubmit 에서 이미 테스트 완료처리 > 페이지 이동만
		doComplete: function () {

			var self = this;
			self.$emit("goNext");
		},


		stopProceed() {
			this.appClose({
				reload: true,
			})
		},


	},
	watch: {

		ansTstList: {
			handler(newVal) {
				console.log(newVal)
			},
			deep: true,
		}

	}
}
</script>

<style scoped>

</style>