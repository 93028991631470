<template>

	<div class="container">
		<div class="content">
			<div class="report-head">
				<span>Level {{level}}</span> - <span>{{ eduMain.shortTitle }}</span> - <span>{{ eduMain.eduTitle }}</span>
			</div>
			<div class="report-cont">
				<div class="score-info">
					<img :src="'/assets/images/learning/' + scoreImg" alt="">
					<span :class="scoreClass" v-html="scoreTxt"></span>
					<!--
					<span class="middle">Good Job!</span>
					<span class="lower">Keep on<br>trying</span>
					-->
					<dl>
						<dt>Score</dt>
						<dd>{{ score }}</dd>
					</dl>
				</div>
				<table class="score-table">
					<caption>Book Test Score</caption>
					<thead>
					<th>테스트 영역</th>
					<th>정답 수 / 문제 수</th>
					</thead>
					<tbody>
					<tr v-for="(item, idx) in groupList">
						<th>{{ item.codeName }}</th>
						<td>{{ item.correctCount }} / {{ item.sumCount }}</td>
					</tr>
					</tbody>
				</table>
				<div class="print-btn">
					<button type="button" @click="doPrint">
						<i></i>
						<span>Print</span>
					</button>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: "tstReport",
	props: [
		'ecIdx',
		'userId',
		'eduMain',
	],
	data () {

		this.$eventBus.$emit("startProgress")

		return {

			level: this.$route.query.level,

			scoreClass: "",
			scoreTxt: "",
			scoreImg: "",
			score: 0,
			groupList: [],
		}
	},
	created() {

		this.getTstResult();
	},
	mounted () {

	},
	methods: {

		// 테스트 결과 조회
		getTstResult () {

			const self = this
			self.axiosCaller.get(self, self.APIs.EDU_ANSWER + "/tstAns/result", {
				ecIdx: self.ecIdx,
				userId: self.userId,
				isDetail: true,
				maxCount: 1,
			}, (res) => {
				const result = res.data
				if ( result.status === "ok" ) {
					console.log(result)
					self.answerVO = result.answerVO
					self.groupList = result.groupList
					self.score = self.numberUtils.toPercent( self.answerVO.tstCorrectCount, self.answerVO.tstSolveCount );

					if ( self.score >= 80 ) {
						self.scoreTxt = "Excellent!";
						self.scoreImg = "img_report_01.png";
						self.scoreClass = "upper";
					} else if ( self.score < 80 && self.score >= 50 ) {
						self.scoreTxt = "Good Job!";
						self.scoreImg = "img_report_02.png";
						self.scoreClass = "middle";
					} else {
						self.scoreTxt = "Keep on<br/>trying";
						self.scoreImg = "img_report_03.png";
						self.scoreClass = "lower";
					}

					this.$nextTick(() => {
						this.$eventBus.$emit("doneProgress")
					})
				}
			})
		},


		doPrint () {
			// document.querySelector('.container').print();
			window.print()
		}


	}
}
</script>

<style scoped>

</style>