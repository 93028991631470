<template>

	<div class="wrap p-video">
		<!-- header -->
		<header class="header-step">
			<div class="head-left">
				<button type="button" class="btn-back" @click="stopProceed"><span class="blind">이전 페이지</span></button>
			</div>
			<div class="head-center">
				<div class="head-tit">
					<span class="page-tit">{{ eduMain.shortTitle }}</span>
					<span class="book-tit">{{ qstMovVO.movTitle }}</span>
				</div>
			</div>
			<div class="head-right"></div>
		</header>
		<!-- container -->
		<div class="container">
			<div class="content">
				<div class="responsive-wrapper">
					<div id="jp_container_video_1" class="jp-flat-video" role="application" aria-label="media player">
						<div id="jquery_jplayer_video_1" class="jp-jplayer"></div>
						<div class="jp-gui" :class="{ 'jp-gui-speed': qstMovVO.isSpeed }">
							<div class="jp-play-control jp-control">
								<button class="jp-play jp-button" role="button" aria-label="play" tabindex="0"></button>
<!--								<button class="jp-pause jp-button" role="button" aria-label="pause" tabindex="0"></button>-->
								<template v-if="qstMovVO.isSpeed">
									<button class="jp-slow jp-button" role="button" aria-label="slow" tabindex="0" @click="doPlayRate('down')"></button>
									<button class="jp-speed jp-button" role="button" aria-label="speed" tabindex="0" @click="doPlayRate('normal')">{{ playRate }}x</button>
									<button class="jp-fast jp-button" role="button" aria-label="fast" tabindex="0" @click="doPlayRate('up')"></button>
								</template>
							</div>
							<div class="jp-bar">
								<div class="jp-seek-bar jp-seek-bar-display"></div>
								<div class="jp-seek-bar">
									<div class="jp-play-bar"></div>
									<div class="jp-details"><span class="jp-title" aria-label="title"></span></div>
									<div class="jp-timing"><span class="jp-duration" role="timer" aria-label="duration"></span></div>
								</div>
							</div>
							<div class="jp-screen-control jp-control">
								<button class="jp-full-screen jp-button" role="button" aria-label="full screen" tabindex="0"></button>
							</div>
						</div>
						<div class="jp-no-solution">
							Media Player Error<br />
							Update your browser or Flash plugin
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import $ from 'jquery'
import jplayer from "jplayer";
//import 'jplayer/dist/skin/pink.flag/css/jplayer.pink.flag.min.css'


export default {
	name: "ansMov",
	components: {
		jplayer
	},
	props: [
		"eduMain",
		"eduContent",
		"eduAnswer",
	],
	data() {

		console.log(this.eduContent.qstMovVO)

		return {

			qstMovVO: this.eduContent.qstMovVO,
			ansMovVO: this.eduAnswer.ansMovVO,

			// 재생상태
			nowPlaying: false,
			isPause: false,
			isDone: false,
			isComplete: false,

			// 현재 플레이시간
			isFullscreen: false,
			playRate: 1,
			currentTimes: 0,
			lastTimes: 0,
			saveInterval: 10,
		}
	},
	created () {

		// //console.log(this.eduMain)
		// //console.log(this.eduContent)
		// //console.log(this.eduAnswer)
	},
	mounted() {

		//console.log(this.qstMovVO)
		//console.log(this.ansMovVO)

		if ( this.ansMovVO.currentTimes > 0) {
			this.currentTimes = this.ansMovVO.currentTimes;
			this.lastTimes = this.ansMovVO.currentTimes;
		}

		this.jplayer()

		window.eduView = this
		window.$VUE = this;
	},
	methods: {

		jplayer: function () {

			var self = this;
			$("#jquery_jplayer_video_1").jPlayer({
				ready: function(event) {
					var $this = $(this).jPlayer("setMedia", {
						// title: "Big Buck Bunny Trailer",
						// m4v: "http://jplayer.org/video/m4v/Big_Buck_Bunny_Trailer.m4v",
						// ogv: "http://jplayer.org/video/ogv/Big_Buck_Bunny_Trailer.ogv",
						// webmv: "http://jplayer.org/video/webm/Big_Buck_Bunny_Trailer.webm",
						// poster: "http://jplayer.org/video/poster/Big_Buck_Bunny_Trailer_480x270.png"

						title: self.qstMovVO.movTitle,
						m4v: self.qstMovVO.movUrl + ((self.qstMovVO.hashParams != null && self.qstMovVO.hashParams != "") ? self.qstMovVO.hashParams : ""),
						poster: self.qstMovVO.movThumb,
					});

                    $('.jp-flat-video-270p').css('width', '100%')

                    // 풀스크린 불가능 영상일 때 풀스크린 버튼 hide
                    if (event.jPlayer.status.noFullWindow) {
                        let $anc = $(event.jPlayer("option", "cssSelectorAncestor"))
                        $anc.find('.jp-screen-control').hide()
                        $anc.find('.jp-bar').css({"right":"0"})
                    }

                    let fixIOSFlash = function() {
                        let w = event.data("jPlayer").ancestorJq.width()
                        let	h = w * 9 / 16
                        event.jPlayer("option", "size", {
                            width: w + "px",
                            height: h + "px"
                        })
                    }

                    let plt = $.jPlayer.platform
                    if (plt.ipad || plt.iphone || plt.ipod || event.jPlayer.flash.used) {
                        $(window).on("resize", function() {
                            fixIOSFlash()
                        })
                        fixIOSFlash()
                    }
				},
				timeFormat: {
					padMin: false
				},
				swfPath: "js",
				supplied: "webmv, ogv, m4v",
				cssSelectorAncestor: "#jp_container_video_1",
				// See the CSS for more info on changing the size.
                size: {
                    width: '100%',
                    height: '100%',
                    cssClass: "jp-flat-video-responsive"
                },
                sizeFull: {
                    width: '100%',
                    height: '100%',
                    cssClass: "jp-flat-video-full"
                },
				autohide: {
					full: false,
					restored: false
				},
				// While playing, allow the GUI to hide
				play: function(e) {
					$(this).jPlayer("option", "autohide", {
						full: true,
						restored: true
					});
					// Avoid multiple jPlayers playing together.
					$(this).jPlayer("pauseOthers");
					self.doPlay();
				},
				// When paused, show the GUI
				pause: function(e) {
					//console.log(e)
					$(this).jPlayer("option", "autohide", {
						full: false,
						restored: false
					});
					self.doPause();
				},
				// Enable clicks on the video to toggle play/pause
				click: function(event) {
					if(event.jPlayer.status.paused) {
						$(this).jPlayer("play");
					} else {
						$(this).jPlayer("pause");
					}
				},
                resize: (ev) => {

                    /**
                     * $('#jquery_jplayer_1').bind($.jPlayer.event.resize + '.jPlayer', function (ev) {})
                     * 보다 빠른 실행
                     */

                    self.isFullscreen = !self.isFullscreen
                    console.log(self.isFullscreen)

                    // 앱에 Fullscreen 상태를 보냄
                    if (self.appDevice() === 'ANDROID') {
                        window.HWAPP.getIsVideoFullscreen(self.isFullscreen)
                    }
                    $('.jp-flat-video-full').css('zIndex', 2000)

                },
				useStateClassSkin: true,
				autoBlur: false,
				smoothPlayBar: !($.jPlayer.browser.msie && $.jPlayer.browser.version < 9), // IE8 did not like the hidden animated progress bar.
				remainingDuration: true,
				keyEnabled: true,
				keyBindings: {
					// Disable some of the default key controls
					loop: null,
					muted: null,
					volumeUp: null,
					volumeDown: null
				}
			});

			// $("#jquery_jplayer_video_1").bind($.jPlayer.event.resize + ".jPlayer", function (e) {     // 화면 리사이즈 시 전체화면일 경우 전체화면 취소
			// 	// Your new repeat handler code
			// 	self.isFullscreen = !self.isFullscreen;
			// 	console.log(self.isFullscreen)
			// });

			$("#jquery_jplayer_video_1").bind($.jPlayer.event.seeked + ".jPlayer", function (e) {     // 프로그레스 스킵 허용시 현재시간 저장
				// Your new repeat handler code
				//console.log(e.jPlayer.status.currentTime)
				if (self.qstMovVO.isSkip && e.jPlayer.status.currentTime > 0) {
					self.saveCurrentTimes();
				}
			});

			$("#jquery_jplayer_video_1").bind($.jPlayer.event.ended + ".jPlayer", function (e) {     // 플레이 완료시
				// Your new repeat handler code
				console.log("ended")
				this.currentTimes = 0;
				self.saveCurrentTimes(0);
				self.goComplete(true);
			});

		},


		doPlay () {

			var self = this
			// TODO : 플레이 시작 + 10초마다 현재 플레이시간 저장
			this.nowPlaying = true;
			this.isPause = false;
			$('#jquery_jplayer_video_1').jPlayer("play", this.currentTimes)
				.bind($.jPlayer.event.timeupdate, self.chkTimes);

			// 재생시간 보다 현재시간이 높을 경우 완료처리
			if (this.ansMovVO.currentTimes >= (this.qstMovVO.playMinutes * 60)) {
				this.isDone = true;
			}

		},


		doPlayRate: function (_mode) {

			if (_mode === 'up' && this.playRate >= 4) {
				return;
			}
			if (_mode === 'down' && this.playRate <= -4) {
				return;
			}

			if (_mode === 'up') {
				this.playRate = (this.playRate * 10 + 0.1 * 10) / 10;
			} else if (_mode === 'down') {
				this.playRate = (this.playRate * 10 - 0.1 * 10) / 10;
			} else {
				this.playRate = 1;
			}

			$("#jquery_jplayer_video_1").jPlayer("option", "playbackRate", this.playRate);
		},


		chkTimes: function (event) {

			var self = this
			var status = event.jPlayer.status;

			// 영상시간 : status.duration
			// 현재 플레이 시간 : status.currentTime
			this.currentTimes = status.currentTime


			if (this.qstMovVO.isSkip) {
				if (this.currentTimes - this.lastTimes >= this.saveInterval) {
					this.saveCurrentTimes();
				}
			} else {

				// done : 마지막 저장시간과 15초 이상 차이 나면 캐슬 후 마지막 저장시간으로 이동
				if (this.currentTimes - this.lastTimes <= (this.saveInterval + 5)) {
					if (this.currentTimes - this.lastTimes >= this.saveInterval) {
						this.saveCurrentTimes();
					}
				} else {
					this.currentTimes = this.lastTimes;
					$('#jquery_jplayer_video_1').jPlayer("play", this.lastTimes);
					self.swalUtils.gritter("임의 구간설정 불가영상", "임의적인 구간설정은 불가능합니다.", "warning")
					//alert("임의적인 구간설정은 불가능합니다.");
					return
				}
			}
			//$('#jplqyer_tmp').text($.jPlayer.convertTime( status.currentTime));
		},


		doPause: function () {

			// TODO : 일시정지 + 현재시간 저장
			this.nowPlaying = false;
			this.isPause = true;
			$('#jquery_jplayer_video_1').jPlayer("pause");

		},


		/*doFullscreen: function() {
			this.isFullscreen = !this.isFullscreen;
			// $('.jp-full-screen').click();
			//console.log("isFullscreen ::::: " + this.isFullscreen);
		},*/


		saveCurrentTimes: function ( _times = null ) {

			var self = this;
			if ( _times == null ) {
				this.ansMovVO.currentTimes = this.currentTimes;
			} else {
				this.ansMovVO.currentTimes = _times;
			}
			this.lastTimes = this.ansMovVO.currentTimes

			this.ansMovVO.currentTimes = Math.floor(this.ansMovVO.currentTimes)
			delete this.ansMovVO.completeDate;

			// 재생시간 보다 현재시간이 높을 경우 완료처리
			if (this.ansMovVO.currentTimes >= (this.qstMovVO.playMinutes * 60)) {
				this.isDone = true;
			}

			this.axiosCaller.patch(this, this.APIs.EDU_ANSWER + "/mov", this.ansMovVO, function (res) {

				const result = res.data;
				if (result.status === "ok") {

					self.lastTimes = self.ansMovVO.currentTimes;

				} else {
					//console.log(result.data.msg);
				}
			})

		},


		goComplete: async function (e, isForce = false) {
			await this.doRecording();
			this.$emit("goComplete", isForce);
		},


		stopProceed() {
			this.appClose({
				reload: false,
			})
		},








		/**
		 * 영상 기록 저장
		 */
		doRecording() {
			const self = this
			return new Promise((resolve, reject) => {

				self.axiosCaller.put(self, self.APIs.EDU_ANSWER + "/record", {
					ecIdx: this.eduContent.ecIdx,
					userId: this.getLoginId(),
					recordMode: "record",
					contentType: "mov",
				}, (res) => {
					const result = res.data
					console.log(result)
					if ( result.status === "ok" ) {
						resolve(result)
					} else {
						reject(result)
					}
				})
			})
		},




	},
}
</script>

<style scoped>

</style>