<template>

	<div class="container">
		<div class="content">
			<div class="img-wrap">
				<img src="/assets/images/learning/img_test_result.png" alt="">
				<span>Good Job!</span>
			</div>
			<div class="score-detail">
				<div class="score-info">
					<dl>
						<dt>Score</dt>
						<dd>{{ score }}</dd>
					</dl>

					<template v-if="3 - tryCount > 0">
						<p>총 3회 테스트를 볼 수 있습니다.</p>
						<p>남은 테스트 응시 횟수 : {{ 3 - tryCount }}회</p>
					</template>
					<template v-else>
						<p>마지막 테스트입니다.</p>
						<p>반드시 저장해주세요.</p>
					</template>

				</div>
				<ul class="button-list">
					<li><a href="javascript:;" v-if="tryCount < 3" @click="doAgain">Try again</a></li>
					<li><a href="javascript:;" class="btn-save" @click="doSave">Save</a></li>
				</ul>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: "tstScore",
	props: [
		'ecIdx',
		'userId'
	],
	data() {

		this.$eventBus.$emit("startProgress")

		return {
			answerVO: null,
			score: 0,
			tryCount: 0,
		}
	},
	created() {

		this.getTstResult();

	},
	mounted () {

	},
	methods: {


		// 테스트 결과 조회
		getTstResult () {

			const self = this
			self.axiosCaller.get(self, self.APIs.EDU_ANSWER + "/tstAns/result", {
				ecIdx: self.ecIdx,
				userId: self.userId,
			}, (res) => {
				const result = res.data
				if ( result.status === "ok" ) {
					self.answerVO = result.answerVO
					self.tryCount = result.tryCount
					self.score = self.numberUtils.toPercent( self.answerVO.tstCorrectCount, self.answerVO.tstSolveCount );

					this.$nextTick(() => {
						this.$eventBus.$emit("doneProgress")
					})
				}
			})
		},


		// 재시도 + 기존 답변들 초기화
		doAgain () {
			var self = this

			self.$eventBus.$emit("startProgress")
			this.axiosCaller.delete( this, this.APIs.EDU_ANSWER + "/tstAns/reset", {
				'ecIdx' : self.ecIdx,
				'eaIdx' : this.answerVO.eaIdx,
				'userId': this.getLoginId(),
			}, function (res) {

				const result = res.data;
				if ( result.status === "ok" ) {
					location.reload()
				}
				self.$eventBus.$emit("doneProgress")

			})
		},


		// 현재 답변 내용 저장(노액션) 및 리포트 화면으로
		async doSave() {

			var self = this

			await this.doRecording();

			self.$eventBus.$emit("startProgress")
			this.axiosCaller.patch(this, this.APIs.EDU_ANSWER + "/", {
				'eaIdx': this.answerVO.eaIdx,
				'userId': this.getLoginId(),
				maxCount: 1,
			}, function (res) {

				const result = res.data;
				if (result.status === "ok") {
					location.reload()
				}
				self.$eventBus.$emit("doneProgress")

			})
		},




		/**
		 * 답변 기록 저장
		 */
		doRecording() {
			const self = this
			return new Promise((resolve, reject) => {

				self.axiosCaller.put(self, self.APIs.EDU_ANSWER + "/record", {
					ecIdx: self.ecIdx,
					userId: this.getLoginId(),
					recordMode: "sticker",
					contentType: "tst",
					maxCount: 1,
				}, (res) => {
					const result = res.data
					if ( result.status === "ok" ) {
						resolve(result)
					} else {
						reject(result)
					}
				})
			})
		},


	}
}
</script>

<style scoped>

</style>